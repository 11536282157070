<template>
    <section class="content mt-5">
        <div class="section-home p-3 mb-3" style="border-radius: 10px;">
            <div class="row">
                <!-- Materi Ajar -->
                <div class="col-4" style="padding-left: 0px;padding-right: 0px;">
                    <router-link to="/materi" v-if="info.status_registrasi == '1'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/materi.png"/></div>
                                <div id="subtitle" class="mt-1">Materi Ajar</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/pilih-paket" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '0'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/materi.png"/></div>
                                <div id="subtitle" class="mt-1">Materi Ajar</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/complete-payment" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '1' && info.status_lunas == '0'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/materi.png"/></div>
                                <div id="subtitle" class="mt-1">Materi Ajar</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/persetujuan" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '1' && info.status_lunas == '1'">
                        <div class="card" >
                            <div class="card-body text-center">
                                <div class="symbol"><img src="@/assets/img/frontpage/materi.png"/></div>
                                <div id="subtitle" class="mt-1">Materi Ajar</div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- Kalender -->
                <div class="col-4" style="padding-left: 0px;padding-right: 0px;">
                    <router-link to="/kalender" v-if="info.status_registrasi == '1'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/kalender.png"/></div>
                                <div id="subtitle" class="mt-1">Kalender</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/pilih-paket" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '0'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/kalender.png"/></div>
                                <div id="subtitle" class="mt-1">Kalender</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/complete-payment" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '1' && info.status_lunas == '0'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/kalender.png"/></div>
                                <div id="subtitle" class="mt-1">Kalender</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/persetujuan" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '1' && info.status_lunas == '1'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/kalender.png"/></div>
                                <div id="subtitle" class="mt-1">Kalender</div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- Tanya Guru -->
                <div class="col-4" style="padding-left: 0px;padding-right: 0px; display: none;">
                    <router-link to="/tanya-guru" v-if="info.status_registrasi == '1'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/tanyaguru.png"/></div>
                                <div id="subtitle" class="mt-2">Tanya Guru</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/pilih-paket" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '0'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/tanyaguru.png"/></div>
                                <div id="subtitle" class="mt-2">Tanya Guru</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/complete-payment" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '1' && info.status_lunas == '0'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/tanyaguru.png"/></div>
                                <div id="subtitle" class="mt-2">Tanya Guru</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/persetujuan" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '1' && info.status_lunas == '1'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/tanyaguru.png"/></div>
                                <div id="subtitle" class="mt-2">Tanya Guru</div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- Latihan Soal -->
                <div class="col-4" style="padding-left: 0px;padding-right: 0px;">
                    <router-link to="/soal" v-if="info.status_registrasi == '1'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/latihan.png"/></div>
                                <div id="subtitle" class="mt-1">Latihan Soal</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/pilih-paket" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '0'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/latihan.png"/></div>
                                <div id="subtitle" class="mt-1">Latihan Soal</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/complete-payment" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '1' && info.status_lunas == '0'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/latihan.png"/></div>
                                <div id="subtitle" class="mt-1">Latihan Soal</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/persetujuan" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '1' && info.status_lunas == '1'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/latihan.png"/></div>
                                <div id="subtitle" class="mt-1">Latihan Soal</div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- Try Out -->
                <div class="col-4" style="padding-left: 0px;padding-right: 0px;">
                    <router-link :to="{
                        name: 'Pilih Try Out',
                        params: { filter: { jenis: 'online' }},
                    }" v-if="info.status_registrasi == '1'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/tryout.png"/></div>
                                <div id="subtitle" class="mt-1">Try Out</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/pilih-paket" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '0'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/tryout.png"/></div>
                                <div id="subtitle" class="mt-1">Try Out</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/complete-payment" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '1' && info.status_lunas == '0'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/tryout.png"/></div>
                                <div id="subtitle" class="mt-1">Try Out</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/persetujuan" v-if="info.status_registrasi == '0' && info.kelengkapan_data == '1' && info.status_lunas == '1'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/tryout.png"/></div>
                                <div id="subtitle" class="mt-1">Try Out</div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- Try Out Event -->
                <div class="col-4" style="padding-left: 0px;padding-right: 0px;" v-if="info.status_registrasi == '1'">
                    <router-link to="/ujian-event">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/to_event.png"/></div>
                                <div id="subtitle" class="mt-1">Try Out Event</div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- Try Out Gratis -->
                <div class="col-4" style="padding-left: 0px;padding-right: 0px;">
                    <router-link to="/ujian-trial" v-if="info.status_registrasi == '0'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/to_free.png"/></div>
                                <div id="subtitle" class="mt-2">Try Out Gratis</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/ujian-trial" v-if="info.status_registrasi == '1'">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/to_free.png"/></div>
                                <div id="subtitle" class="mt-2">Try Out Gratis</div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- Rapor Evaluasi -->
                <div class="col-4" style="padding-left: 0px;padding-right: 0px;" v-if="info.status_registrasi == '1'">
                    <router-link to="/rapor/jenis">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/rapor.png"/></div>
                                <div id="subtitle2" class="mt-2">Rapor Evaluasi</div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- Hubungi Admin -->
                <div class="col-4" style="padding-left: 0px;padding-right: 0px;">
                    <a href="https://wa.me/6285817409928?text=Halo%20Admin%20Taruna%20..." target="blank">
                        <div class="col-sm d-flex">
                            <div class="card card-body text-center flex-fill">
                                <div class="symbol"><img src="@/assets/img/frontpage/wa.png"/></div>
                                <div id="subtitle2">Hubungi Admin</div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <h4 v-if="info.kelengkapan_data">Agenda Terdekat</h4>
        <div class="mb-3" v-if="agenda.length > 0 && info.kelengkapan_data">
            <vue-horizontal-list :items="agenda" :options="{
                responsive: [
                { end: 576, size: 1 },
                { start: 576, end: 768, size: 2 },
                { size: 3 },
                ],}">
                <template v-slot:default="{item}">
                    <router-link :to="{path: '/kalender' ,
                                query: { k_id: item.url },}">
                        <div class="info-box">
                            <span class="info-box-icon bg-pink elevation-1">
                                <div>{{item.date}}</div>
                                <div class="muted month">{{item.month}}</div>
                            </span>
                            <div class="info-box-content">
                                <strong>{{item.mapel}}</strong>
                                <span class="info-box-text">{{item.materi}}</span>
                                <span class="info-box-text">{{item.time}}</span>
                                <span class="info-box-text">{{item.room}}</span>
                            </div>
                            <!-- /.info-box-content -->
                        </div>
                    </router-link>
                </template>
            </vue-horizontal-list>
        </div>
        <h4>Promosi</h4>
        <div class="mb-3" v-if="promotions.length > 0">
            <!--Panel Promosi-->
            <vue-horizontal-list :items="promotions" :options="{
                responsive: [
                { end: 576, size: 1 },
                { start: 576, end: 768, size: 2 },
                { size: 3 },
                ],}">
                <template v-slot:default="{item}">
                    <router-link :to="{ path: '/berita-detail/'+item.url }">
                        <div class="card mb-2">
                            <img class="" width="100%" height="200px" style="object-fit:cover" :src="url + '/file/image/' + item.img_cover" :alt="item.img">
                            <div class="card-body">
                                <h4 class="card-title">{{ item.title }}</h4>
                                <div class="card-text desc">{{ item.desc }}</div>
                            </div>
                        </div>
                    </router-link>
                </template>
            </vue-horizontal-list>
        </div>
        <h4>Tanya Guru</h4>
        <div class="mb-3">
            <div class="card gradient " style="min-height: 100px;">
                <div class="clearfix p-3" style="z-index: 60;">
                    <div class="float-left">
                        <img class="" width="90" src="../assets/img/question.png" alt="Card image cap" style="border-radius: 8px;">
                    </div>
                    <div class="" style="margin-left:108px;">
                        <h4>KAMU BISA TANYA LANGSUNG</h4>
                        <p>Kalau bingung atau ada materi yang kurang jelas, langsung saja tanya ke Guru terkait ya.</p>
                    </div>
                    <div class="float-right">
                        <router-link to="/tanya-guru" class="btn btn-primary">Mulai <i class="fa fa-arrow-right"></i></router-link>
                    </div>
                </div>
                <div class="overlay"></div>
            </div>
        </div>
        <!--Panel Berita-->
        <h4>Pengumuman</h4>
        <div class="mb-3" v-if="woroworo.length > 0">
            <vue-horizontal-list :items="woroworo" :options="{
                responsive: [
                { end: 576, size: 1 },
                { start: 576, end: 768, size: 2 },
                { size: 3 },
                ],}">
                <template v-slot:default="{item}">
                    <router-link :to="{ path: '/pengumuman-detail/'+item.url }">
                        <div class="card mb-2">
                            <img class="" width="100%" height="200px" style="object-fit:cover" :src="url + '/file/image/' + item.img_cover" :alt="item.img">
                            <div class="card-body">
                                <h4 class="card-title mb-2">{{ item.title }}</h4>
                                <div class="card-text desc">{{ item.desc }}</div>
                            </div>
                        </div>
                    </router-link>
                </template>
            </vue-horizontal-list>
        </div>
        <!--Panel Berita-->
        <h4>Berita</h4>
        <div class="mb-3" v-if="infoNews.length > 0">
            <vue-horizontal-list :items="infoNews" :options="{
                responsive: [
                { end: 576, size: 1 },
                { start: 576, end: 768, size: 2 },
                { size: 3 },
                ],}">
                <template v-slot:default="{item}">
                    <router-link :to="{ path: '/berita-detail/'+item.url }">
                        <div class="card mb-2">
                            <img class="" width="100%" height="200px" style="object-fit:cover" :src="url + '/file/image/' + item.img_cover" :alt="item.img">
                            <div class="card-body">
                                <h4 class="card-title mb-2">{{ item.title }}</h4>
                                <div class="card-text desc">{{ item.desc }}</div>
                            </div>
                        </div>
                    </router-link>
                </template>
            </vue-horizontal-list>
        </div>
    </section>
</template>
<script>
    import VueHorizontalList from "vue-horizontal-list";
    import { auth, authFetch } from "@/libs/hxcore";
    import moment from "moment";
    import $ from 'jquery';
    export default {
    name: 'Home',
    components: {
        VueHorizontalList,
      },
    data() {
        return {
            imgUrl: 'noimg',
            agenda: [],
            info: [],
            infoNews: [],
            woroworo: [],
            promotions: [],
            url: '',
            promotionGroups: []
        };
    },
    created: function () {
        this.url = window.$apiUrl;
        this.loadBerita();
        this.loadPromosi();
        this.loadPengumuman();
        this.loadAgenda();
        var user = auth.user();

        $('body').removeClass('sidebar-open')
        $('body').addClass('sidebar-close')
        $('body').addClass('sidebar-collapse')

        authFetch('/masterdata/siswa/info/'+user.id)
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 600) {}
                return res.json();
            })
            .then(js => {
                this.info = js.data;
            })
    },
    methods: {
        loadBerita(){
            authFetch("/siswa/frontpage/berita").then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        let arr = [];
                        for (let i = 0; i < json.data.length; i++) {
                            arr = json.data[i];

                            this.infoNews.push({
                            filepath: arr.file_path,
                            img: arr.file_name,
                            title: arr.title,
                            url: arr.id,
                            desc: arr.deskripsi,
                            img_cover: arr.img_cover
                            });
                        }

                    }
                });
            });
        },
        loadPromosi(){
            authFetch("/siswa/frontpage/promosi").then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        let arr = [];
                        for (let i = 0; i < json.data.length; i++) {
                            arr = json.data[i];

                            this.promotions.push({
                            filepath: arr.file_path,
                            img: arr.file_name,
                            title: arr.title,
                            url: arr.id,
                            desc: arr.deskripsi,
                            img_cover: arr.img_cover
                            });
                        }
                    }
                });
            });
        },
        loadPengumuman(){
            authFetch("/siswa/frontpage/pengumuman").then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        let arr = [];
                        for (let i = 0; i < json.data.length; i++) {
                            arr = json.data[i];

                            this.woroworo.push({
                            filepath: arr.file_path,
                            img: arr.file_name,
                            title: arr.title,
                            url: arr.id,
                            desc: arr.deskripsi,
                            img_cover: arr.img_cover
                            });
                        }
                    }
                });
            });
        },
        loadAgenda(){
            authFetch("/siswa/frontpage/agenda").then((res) => {
                res.json().then((json) => {
                    if (json.success) {
                        let arr = [];
                        for (let i = 0; i < json.data.length; i++) {
                            arr = json.data[i];
                            
                            let start = arr.time_start;
                            let end = !arr.time_end ? 'selesai' : arr.time_end.split(":00+")[0];

                            this.agenda.push({
                                mapel: arr.mapel,
                                materi: arr.materi,
                                date: arr.tgl,
                                month: arr.bln,
                                time: start.split(":00+")[0] + ' - ' + end,
                                room: arr.kelas,
                                url: arr.k_id,
                                description: arr.keterangan
                            });
                        }

                        console.log(this.agenda);
                    }
                });
            });
        },
    },
}
</script>
<style type="text/css">
.main-header {
    border-bottom: 0 !important;
}

.section-home {
    background: #F3F3F3;
}

.section-home .card-body {
    min-height: 100px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2rem;
}

.symbol {
    /* font-size: 28px; */
    margin-bottom: 5px;
    margin-top: -10px;
}

.info-box {
    background: linear-gradient(90deg, #C9D6FF 0%, #E2E2E2 100%);
}

.bg-pink {
    background-color: #FF8181 !important;
}

.live__scroll {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.live__scroll .row {
    display: block;
}

.live__scroll .live__scroll--box {
    display: inline-block;
    float: none;
    width: 60%;
    padding: 15px;
}

.month {
    font-size: 10px;
}

.info-box .info-box-icon {
    display: block;
}

.card.gradient {
    background: linear-gradient(90deg, #DD5E89 0%, #F7BB97 100%);
    background-image: url("../assets/img/bg-catt.jpeg");
    background-size: cover;
    background-position: center;
    color: white;
}

.card .overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    width: 100%;
    transition: .5s ease;
    opacity:0.5;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
}

.desc{
    width: 300px;
    white-space:pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
}
.vhl-container{
    margin-right:0 !important;
}

@media only screen and (max-width: 555px) {
    #subtitle { font-size: 0.80rem; color: black; }
    #subtitle2 { font-size: 0.77rem; color: black; }
}

@media only screen and (min-width: 650px) {
    #subtitle { font-size: 0.95rem; color: black;}
    #subtitle2 { font-size: 0.90rem; color: black; }
}
.flex-fill {
    -webkit-box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}
</style>